<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma mr10" type="primary" @click="showAddModel">新增楼宇</el-button>
                <el-button class="ma" type="primary" @click="showImportHouse = true">导入房屋</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="buildName" align="center" label="楼宇名称" show-overflow-tooltip />
                <el-table-column prop="buildNum" align="center" label="单元数量" show-overflow-tooltip />
                <el-table-column prop="buildLayers" align="center" label="楼宇楼层" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.buildLayers }}层
                    </template>
                </el-table-column>
                <el-table-column prop="buildTypeName" align="center" label="楼宇类型" show-overflow-tooltip />
                <el-table-column prop="houseNum" align="center" label="已有房屋" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.houseNum }}套
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button type="text" @click="hrefFun(scope.row)">查看房屋</el-button>
                        <!-- <el-button class="mr10" style="color:#F56C6C" type="text" @click="showUpdateModel(scope.row)">删除
                        </el-button> -->
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="400px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="楼宇名称" prop="buildName">
                    <el-input class="w220" v-model="formData.buildName" clearable placeholder="请输入楼宇名称"></el-input>
                </el-form-item>
                <el-form-item label="楼宇层数" prop="buildLayers">
                    <el-input class="w220 suffixInput" placeholder="请输入楼宇层数" v-model="formData.buildLayers" clearable
                        type="number">
                        <template slot="append">层</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="楼宇类型" prop="buildType">
                    <el-select class="w220" v-model="formData.buildType" filterable clearable>
                        <el-option v-for="(name, id) in buildTypeData" :key="id" :label="name" :value="id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="单元数量" prop="buildNum">
                    <el-input class="w220" v-model="formData.buildNum" clearable placeholder="请输入单元数量" type="number">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="导入房屋" :visible.sync="showImportHouse"
            :destroy-on-close="true" width="400px" center>
            <el-tabs v-model="importType">
                <el-tab-pane label="表格导入" name="table">

                </el-tab-pane>
                <el-tab-pane label="批量导入" name="batch">

                </el-tab-pane>
            </el-tabs>
            <div v-if="importType === 'table'">
                <p class="fs6 mb20 mt20">请先下载导入模板文件，按照文件内的内容编辑后再"选择楼宇上传文件"即可批量导入。</p>
                <div class="mt20 mb20">导入模板：<a class="cblue ml20" href="#" @click="downloadModel">点击下载</a></div>
                <div class="mt20 mb20">
                    <i style="color:#F56C6C">*</i> 所属楼宇：<el-select v-model="importBuildId" filterable remote
                        placeholder="请输入关键词" :remote-method="getBuilDingDataFun" :loading="loadingBuilDingData">
                        <el-option v-for="item in builDingList" :key="item.buildId" :label="item.buildName"
                            :value="item.buildId">
                        </el-option>
                    </el-select>
                </div>
                <div class="d-flex w320 posre">
                    <el-upload class="mt20 mb20" action="/api/crmPc/property/importHouseManage"
                        :before-upload="importHouseFun" :data="{ buildId: importBuildId }" :on-success="importHouseSuccess"
                        :file-list="importHouseFile">
                        <el-button size="small" type="primary">导入房屋</el-button>
                    </el-upload>
                </div>
                <div class="mt40 flex-c-c">
                    <el-button type="primary" plain @click="showImportHouse = false">
                        关 闭
                    </el-button>
                </div>
            </div>
            <div v-if="importType === 'batch'">
                <p class="fs6 mb10 mt20 corange">该批量导入适用于”多房屋数据相同“的情况，请谨慎操作</p>
                <p class="fs6 mb20">请”选择楼宇，填写每层房屋数量、面积“即可批量导入（房屋名则按照”楼/数量“来取名，例如：201~203）。</p>
                <el-form :model="importData" :rules="importRules" ref="importData" label-width="110px">
                    <el-form-item label="所属楼宇" prop="buildId">
                        <el-select class="w220" v-model="importData.buildId" filterable remote placeholder="请输入关键词"
                            :remote-method="getBuilDingDataFun" :loading="loadingBuilDingData">
                            <el-option v-for="item in builDingList" :key="item.buildId" :label="item.buildName"
                                :value="item.buildId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="每层房屋数量" prop="layersHouseNum">
                        <el-input class="w220 suffixInput" v-model="importData.layersHouseNum" type="number"
                            placeholder="请输入房屋数量">
                            <template slot="append">套</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="房屋类型" prop="houseType">
                        <el-select class="w220" v-model="importData.houseType" placeholder="请选择房屋类型">
                            <el-option v-for="(name, id) of houseTypeData" :key="id" :value="Number(id)" :label="name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="房屋面积" prop="houseArea">
                        <el-input class="w220 suffixInput" v-model="importData.houseArea" placeholder="请输入建筑面积"
                            type="number">
                            <template slot="append">m²</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="车位面积" prop="carArea">
                        <el-input class="w220 suffixInput" v-model="importData.carArea" placeholder="请输入车位面积" type="number">
                            <template slot="append">m²</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="阁楼面积" prop="carArea">
                        <el-input class="w220 suffixInput" v-model="importData.loftArea" placeholder="请输入阁楼面积"
                            type="number">
                            <template slot="append">m²</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="储藏室面积" prop="storageArea">
                        <el-input class="w220 suffixInput" v-model="importData.storageArea" placeholder="请输入储藏室面积"
                            type="number"><template slot="append">m²</template>
                        </el-input>
                    </el-form-item>
                </el-form>
                <div class="mt40 flex-c-c">
                    <el-button @click="showImportHouse = false">取 消</el-button>
                    <el-button type="primary" @click="importFun">批量导入</el-button>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getBuildManageList, setBuildManage, getByDictType, setHouseManageList } from "@/api/propertyServices";
import axios from 'axios'
export default {
    name: "buildingTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                buildName: "",//楼宇名称
                parkId: "",//园区id
                buildLayers: "",//楼宇层数
                buildNum: "",//单元数量
                buildType: "",//楼宇类型
            },
            rules: {
                buildName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入楼宇名称",
                },
            },
            formType: "",
            updateId: 0,
            parkId: 0,
            buildTypeData: {},
            //导入配置
            importHouseFile: [],
            showImportHouse: false,
            importBuildId: "",
            loadingBuilDingData: false,
            builDingList: [],
            buildNum: 0,
            buildLayers: 0,
            importType: "table",
            importRules: {
                buildId: [{
                    required: true,
                    trigger: "change",
                    message: "请选择楼宇",
                }],
                layersHouseNum: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入每层房屋数量",
                }],
                houseArea: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入房屋面积",
                }],
            },
            houseTypeData: {
                0: "住房", 1: "办公室", 2: "商铺", 3: "厂房"
            },
            importData: {
                buildId: "",//楼宇id
                layersHouseNum: "",//每层房屋数量
                carArea: "",//车位面积
                houseArea: "",//房屋面积
                houseType: "",//房屋类型（0：住房，1：办公室，2：商铺，3：厂房）
                loftArea: "",//	阁楼面积
                storageArea: "",//储藏室面积
            },
        };
    },
    filters: {

    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark;
        }
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.currentPage = 1
            this.getBuilDingDataFun();
            this.getList();
        }
    },
    async created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        await this.getInit();
        this.getBuilDingDataFun();
        this.getList();
    },
    methods: {
        /**@method 批量导入 */
        importFun() {
            this.$refs.importData.validate((valid) => {
                if (valid) {
                    this.$confirm('此操作将批量导入”多条相同的房屋数据“, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        setHouseManageList(this.importData).then(res => {
                            this.$message({
                                type: 'success',
                                message: res.data
                            });
                            this.getList();
                        }).catch(err => {
                            if (err.code === 201) {
                                this.$message({
                                    type: 'error',
                                    message: err.message
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }
            })
        },
        /**@method 获取选择楼宇 */
        async getBuilDingDataFun(str) {
            let res = await getBuildManageList({ buildName: str, pageNum: 1, pageSize: 30, parkId: this.parkId, })
            try {
                this.builDingList = res.data.pageInfo.list
            } catch (err) {
                this.builDingList = [];
            }
        },
        /**@method 导入成功回调 */
        importHouseSuccess(res) {
            //-console.log(res);
            if (res.code === 200) {
                this.$message({
                    message: res.data,
                    type: "success"
                })
            }
        },
        /**@method 导入房屋验证 */
        importHouseFun(file) {
            //console.log(file.type);
            if (file.type !== 'application/vnd.ms-excel' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                this.$message({
                    message: "请使用模板文件导入房屋!",
                    type: "error"
                })
                return false;
            }

            if (!this.importBuildId) {
                this.$message({
                    message: "请选择楼宇!",
                    type: "error"
                })
                return false;
            } else {
                this.$message({
                    message: "导入中...",
                    type: "info"
                })
            }
        },
        /**@method 下载模板 */
        downloadModel() {
            this.$message({
                message: "下载模板中...",
                type: "info"
            })
            axios.post('/api/crmPc/property/exportHouseManage', {}, { responseType: 'blob' }).then(res => {
                //-console.log(res);
                let link = document.createElement('a');
                let url = window.URL.createObjectURL(res);
                link.href = url;
                link.download = '房屋导入模板.xls';

                link.click();
                window.URL.revokeObjectURL
            });
        },
        /**@method 查看房屋 */
        hrefFun(row) {
            this.$router.push({
                path: "/buildHouse?buildId=" + row.buildId
            });
        },
        /**@method 搜索 */
        searchFun() { this.currentPage = 1; this.getList() },
        /**@method 获取楼宇类型 */
        async getInit() {
            try {
                let res = await getByDictType({ dictType: "build_type", status: 0 })
                let obj = {}
                for (let row of res.data.sysDictDataList) {
                    obj[row.dictValue] = row.dictLabel;
                }
                this.buildTypeData = obj;
                //-console.log(obj);
            } catch (err) {
                this.buildTypeData = {};
            }
        },
        /**@method 新增/编辑 */
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate(async (valid) => {
                if (valid) {
                    if (this.formData.buildNum < this.buildNum) {
                        this.$message({
                            message: "修改的单元数量不能小于之前的数量",
                            type: "error"
                        });
                        return;
                    }
                    if (this.formData.buildLayers < this.buildLayers) {
                        this.$message({
                            message: "修改的楼宇层数不能小于之前的层数",
                            type: "error"
                        });
                        return;
                    }
                    let params = {
                        ...this.formData,
                        parkId: this.parkId,
                    };
                    let message = "新增成功";
                    if (this.formType === 'update') {
                        params.buildId = this.updateId;
                        message = "编辑成功"
                    }
                    let res = await setBuildManage(params);
                    if (res.code === 200) {
                        this.$message({
                            message: message,
                            type: "success"
                        })
                        this.showModel = false;
                        this.getList();
                    }

                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            try {
                let params = {
                    pageSize: this.pageSize,
                    pageNum: this.currentPage,
                    parkId: this.parkId,
                    buildName: this.searchData.name
                };
                this.loading = true;
                let res = await getBuildManageList(params);
                this.loading = false;
                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    row.buildTypeName = this.buildTypeData[row.buildType]
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增楼宇";
            this.formType = "add";
            this.formData = {
                buildName: "",//楼宇名称
                parkId: "",//园区id
                buildLayers: "",//楼宇层数
                buildNum: "",//单元数量
                buildType: "",//楼宇类型
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑楼宇";
            this.formType = "update";
            this.updateId = row.buildId;
            this.buildNum = row.buildNum;
            this.buildLayers = row.buildLayers;
            this.formData = {
                buildName: row.buildName,//楼宇名称
                parkId: row.parkId,//园区id
                buildLayers: row.buildLayers,//楼宇层数
                buildNum: row.buildNum,//单元数量
                buildType: row.buildType,//楼宇类型
            };
        },
    },
};
</script>

<style>
.el-upload--text {
    width: 90px;
    height: 36px;
    border: 0px;
}
</style>
<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>